import auth0 from 'auth0-js'
import { navigate } from 'gatsby'
import { updateUserData } from '@src/state/initialState'
import { userVar } from '../state/initialState'
import sleep from '@src/helpers/sleep'

const isBrowser = typeof window !== 'undefined'

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      clientID: process.env.GATSBY_AUTH0_CLIENTID,
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
      responseType: 'token id_token',
      scope: 'openid profile email',
    })
  : {}

export const isAuthenticated = () => {
  if (!isBrowser) return

  return localStorage.getItem('isLoggedIn') === 'true'
}

export const login = () => {
  if (!isBrowser) return
  auth.authorize()
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate('/')
    cb()
    return
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

    const loginData = {
      id: authResult.idTokenPayload.sub,
      tokenId: authResult.idToken,
      accessToken: authResult.accessToken,
      email: authResult.idTokenPayload.email,
      expiresAt,
    }
    localStorage.setItem('isLoggedIn', 'true')
    updateUserData({ ...loginData, isLoggedIn: true })
    navigate('/')
    cb()
  }
}

export const silentAuth = (callback: any) => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = () => {
  if (!isBrowser) return

  auth.parseHash(setSession())
}

export const getProfile = () => userVar()

const localLogOut = async () => {
  await sleep(500)
  if (isBrowser) localStorage.setItem('isLoggedIn', 'false')
  updateUserData({
    id: null,
    tokenId: null,
    accessToken: null,
    email: null,
    isLoggedIn: false,
    expiresAt: null,
  })
}

export const logout = () => {
  localLogOut()
  auth.logout()
}
