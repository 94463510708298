import React from 'react'
import BackgroundImage from './backgroundImage'
import Verify from './verify-authentication'
import { login } from '@src/utils/auth'

import Logo from './logo'

import styles from './styles.module.css'

const Authentication: React.FC = () => {
  if (
    typeof window !== 'undefined' &&
    window.location?.pathname === '/verify-authentication'
  )
    return <Verify />

  return (
    <BackgroundImage image="login-back.png" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo />
          <div className={styles.divider} />
          <div className={styles.description}>Admin</div>
        </div>
        <div className={styles.buttonContent}>
          <div className={styles.button} onClick={() => login()}>
            INICIAR SESIÓN
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Authentication
