import React, { useEffect } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { VERIFICATION } from './login.gql'
import BackgroundImage from './backgroundImage'
import { updateUserData, clearUserData } from '@src/state/initialState'
import { handleAuthentication } from '@src/utils/auth'
import { userVar } from '@src/state/initialState'
import { logout } from '@src/utils/auth'
import { navigate } from 'gatsby'
import { message } from 'antd'

import { Spin } from 'antd'
import Logo from '../logo'

import styles from './styles.module.css'

interface VerifyAdminVars {
  tokenId?: string
  accessToken: string
  email: string
  auth0UserId: string
}

const VerifyAuthentication: React.FC = () => {
  const [verifyUser] = useMutation<VerifyAdminVars>(VERIFICATION)
  const user = useReactiveVar(userVar)

  useEffect(() => {
    handleAuthentication()
  }, [])

  useEffect(() => {
    const onAuthentication = async (params: {
      tokenId?: string
      accessToken: string
      email: string
      auth0UserId: string
    }) => {
      try {
        const response = await verifyUser({ variables: { input: params } })
        if (!response || !response.data) return
        return true
      } catch (error) {
        console.log(error)
      }
    }

    const verifyUserOnServer = async () => {
      if (user.accessToken) {
        const params = {
          tokenId: user.tokenId,
          accessToken: user.accessToken,
          email: user.email,
          auth0UserId: user.id,
        }

        const lastAuthenticate = await onAuthentication(params)
        if (lastAuthenticate) {
          updateUserData({ ...user, isLoggedIn: true })
          navigate('/professionals')
        } else {
          clearUserData()
          message.error('User is not authorized')
          logout()
        }
      }
    }

    verifyUserOnServer()
  }, [user, verifyUser])

  return (
    <BackgroundImage image="login-back.png" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo />
          <div className={styles.divider} />
          <div className={styles.description}>Admin</div>
        </div>
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      </div>
    </BackgroundImage>
  )
}

export default VerifyAuthentication
