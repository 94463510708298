/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from '../header'
import './styles.module.css'
import { silentAuth } from '@src/utils/auth'

const Layout: React.FC = ({ children }) => {
  const url = typeof window !== 'undefined' && window.location.href
  const isLogin = url && url.includes('authentication')
  const [loading, setLoading] = useState(true)

  const handleCheckSession = () => {
    setLoading(false)
  }

  useEffect(() => {
    silentAuth(handleCheckSession)
  }, [])

  if (isLogin) return <>{children}</>

  return (
    !loading && (
      <>
        <Header />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: '65%',
            // padding: `0 1.0875rem 1.45rem`,
          }}>
          <main>{children}</main>
          <footer
            style={{
              height: 50,
              margin: `0 auto`,
              maxWidth: '65%',
              // padding: `0 1.0875rem 1.45rem`,
            }}>{` `}</footer>
        </div>
      </>
    )
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
