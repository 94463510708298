export default [
  {
    path: '/professionals',
    name: 'Profesionales',
  },
  {
    path: '/services',
    name: 'Servicios',
  },
  {
    path: '/payments',
    name: 'Pagos',
  },
  {
    path: '/users',
    name: 'Usuario',
  },
]
