import { makeVar } from '@apollo/client'

export interface User {
  id: string
  email: string
  tokenId: string
  accessToken: string
  isLoggedIn: boolean
  expiresAt: number
}

export type AppUser = User

export const userInitialValue: AppUser = {
  id: null,
  tokenId: null,
  email: null,
  accessToken: null,
  isLoggedIn: false,
  expiresAt: null,
}

export const userVar = makeVar<AppUser>(userInitialValue)

export const updateUserData = (values: User) => {
  userVar({ ...values })
}

export const clearUserData = () => {
  userVar({
    id: null,
    tokenId: null,
    email: null,
    accessToken: null,
    isLoggedIn: false,
    expiresAt: null,
  })
}
