import React, { useState } from 'react'
import styles from './styles.module.css'
import { navigate } from 'gatsby'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { logout } from '@src/utils/auth'
import { BoxStyled } from './styles'
import pages from './paths'
import Logo from '../logo'

const Header: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  const onLogout = () => {
    logout()
  }

  const goHome = () => navigate('/professionals')

  const renderExample = () => {
    return (
      <BoxStyled animate={isVisible ? 'visible' : 'hidden'}>
        <div className={styles.options}>Salir</div>
        <div className={styles.options} onClick={onLogout}>
          Cerrar sesión
        </div>
      </BoxStyled>
    )
  }

  const renderPaths = () => {
    return pages.map((page, idx) => {
      const path = typeof window !== 'undefined' && window?.location?.pathname
      const active = page.path === path
      return (
        <div
          className={active ? styles.activePath : styles.path}
          key={idx.toString()}
          onClick={() => navigate(page.path)}>
          {page.name}
        </div>
      )
    })
  }

  const renderUserOptions = () => {
    return (
      <div className={styles.optionsContainer}>
        {renderExample()}
        {renderPaths()}
        <div className={styles.icon}>
          <MdKeyboardArrowDown
            onClick={() => setIsVisible(prevState => !prevState)}
            size={20}
            color="#DADADA"
          />
        </div>
      </div>
    )
  }

  return (
    <header
      style={{
        background: `white`,
        marginBottom: `1.45rem`,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.12)',
      }}>
      <div className={styles.container}>
        <div className={styles.logoContent} onClick={goHome}>
          <Logo />
          <div className={styles.divider} />
          <div className={styles.description}>Admin</div>
        </div>
        {renderUserOptions()}
      </div>
    </header>
  )
}

export default Header
