export default () => {
  const url = typeof window !== 'undefined' && window?.location?.href

  return url && url.includes('localhost')
    ? 'http://localhost:7000'
    : process.env.GATSBY_ENVIRONMENT === 'dev'
    ? process.env.GATSBY_DEV_API_URL
    : process.env.GATSBY_ENVIRONMENT === 'prod'
    ? process.env.GATSBY_PROD_API_URL
    : ''
}
