import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: { opacity: 1, transition: { duration: 0.3 } },
  hidden: { opacity: 0 },
}

export const BoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  z-index: 100;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  top: 70px;
  width: 15%;
  right: 20%;
`
