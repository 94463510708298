import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

type BackgroundType = {
  className: object
  image: string
}

const BackgroundSection: React.FC<BackgroundType> = ({
  className,
  image,
  children,
  backgroundColor,
}) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "login-back.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section" className={className} fluid={imageData}>
          {children}
        </BackgroundImage>
      )
    }}
  />
)

export default BackgroundSection
