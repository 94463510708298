import React, { useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import { ApolloProvider, useQuery, useReactiveVar } from '@apollo/client'
import Authentication from '@src/pages/authentication'
import esES from 'antd/es/locale/es_ES'
// import { Auth0Provider } from '@auth0/auth0-react'
import { client } from './apollo'
import { USER } from '@src/helpers/localQueries/userQuery'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isToday from 'dayjs/plugin/isToday'
import isBetween from 'dayjs/plugin/isBetween'

import { Helmet } from 'react-helmet'
import 'moment/locale/es'

import dayjs from 'dayjs'
import { userVar } from './initialState'
import { silentAuth } from '@src/utils/auth'

dayjs.locale('es') // use Spanish locale globally
dayjs.extend(advancedFormat)
dayjs.extend(isToday)
dayjs.extend(isBetween)

const IsLoggedIn = ({ element, user }) => {
  console.log(user, element)
  return user?.isLoggedIn ? element : <Authentication />
}

type Props = {
  element: React.FC
}

const RootContainer: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const user = useReactiveVar(userVar)

  useEffect(() => {
    silentAuth(() => setLoading(false))
  }, [])

  return (
    !loading && (
      <ApolloProvider client={client}>
        <ConfigProvider locale={esES}>
          <div className="application">
            <Helmet>
              <meta charSet="utf-8" />
              <title>The Cat Club</title>
              <link rel="canonical" href="https://admin.thecatclub.cl/" />
            </Helmet>
          </div>
          <IsLoggedIn element={children} user={user} />
        </ConfigProvider>
      </ApolloProvider>
    )
  )
}

export default ({ element }: Props) => <RootContainer>{element}</RootContainer>
